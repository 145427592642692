<template>
  <div class="max">
    <!-- 左侧部分 -->
    <div class="left">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="top"
      >
        <div class="left_shaixuan">
          <!-- 选择时间 -->
          <el-form-item prop="value2" class="time">
            <el-date-picker
              class="riqi"
              v-model="ruleForm.value2"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
          <!-- 对话线索 -->

          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
          >对话线索</el-checkbox>
          <div style="margin: 5px 0;"></div>
          <el-form-item prop="checkedCities" class="time">
            <el-checkbox-group v-model="ruleForm.checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox class="duihua" v-for="city in cities" :label="city" :key="city">
                {{
                city
                }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 对话类型 -->

          <el-select v-model="value" class="leixin" placeholder="对话类型" @change="xuan(value)">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-form-item prop="checkedCities1" class="time">
            <el-checkbox-group v-model="ruleForm.checkedCities1">
              <el-checkbox class="duihua" v-for="city1 in cities1" :label="city1" :key="city1">
                {{ city1
                }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- 访问来源 -->

          <el-form-item label="访问来源" effect="dark" prop="laiyuan" class="mgbo">
            <el-tooltip
              class="item"
              effect="dark"
              content="访客通过各种渠道进入网站,主要包括外部链接、搜索引擎、直接访问"
              placement="bottom-start"
            >
              <i class="el-icon-info laiyuan"></i>
            </el-tooltip>
            <el-input v-model="ruleForm.laiyuan"></el-input>
          </el-form-item>
          <el-form-item label="搜索引擎" prop="suoyou" class="mgbo">
            <el-select class="yinqin" v-model="ruleForm.suoyou" placeholder="请选择">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="搜索词" prop="type" class="mgbo">
            <el-tooltip
              class="item"
              effect="dark"
              content="搜索词或推广链接设置的关键词"
              placement="bottom-start"
            >
              <i class="el-icon-info ssc"></i>
            </el-tooltip>
            <el-input v-model="ruleForm.type"></el-input>
          </el-form-item> -->
        </div>
        <div class="left_dianji">
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">开始筛选</el-button>
            <el-button class="chongzhi" @click="resetForm('ruleForm')">清除筛选</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <!-- 右侧部分 -->
    <div class="right">
      <!-- <div class="right_top">
        <el-row>
          <el-button type="text" @click="dialogVisible = true" class="xianshi" plain>显示字段设置</el-button>
        </el-row>

        <el-dialog class="ziduan" title="设置显示字段" :visible.sync="dialogVisible" width="380px">
          <el-form ref="formziduan" :model="formziduan">
            <el-checkbox-group v-model="formziduan.checkList">
              <ul>
                <li>
                  <el-checkbox label="访客名称-name" disabled>访客名称</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="接待客服-address" disabled>接待客服</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="访客标签-baioqian">
                    访客标签
                    <el-tooltip class="item" effect="dark" content="客户对该访客的归类" placement="bottom">
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="对话时间-date">对话时间</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="对话数(客：访)-duihua">对话数(客：访)</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="访问来源-laiyuan">
                    访问来源
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="访客通过各种渠道进入网站,主要包括外部链接、搜索引擎、直接访问"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="搜索词-ssc">
                    搜索词
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="搜索词或推广链接设置的关键词"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="着陆页-zly">
                    着陆页
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="访客到达的第一个页面。访客点击广告或者搜索引擎结果后显示给访客的网页"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="推广链接-lianjie">推广链接</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="咨询页-zxy">
                    咨询页
                    <el-tooltip class="item" effect="dark" content="建立对话页面" placement="bottom">
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="风格-fengge">
                    风格
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="来自不同的 53KF 挂码地址"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
              </ul>
              <ul>
                <li>
                  <el-checkbox label="地区-dq">地区</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="访客IP-ip">访客IP</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="对话渠道-dhqd">
                    对话渠道
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="包括PC、移动(不含微信)、微信"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="消息类型-xxlx">
                    消息类型
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="包括在线消息、留言消息、机器人消息"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="对话主题-dhzt">
                    对话主题
                    <el-tooltip class="item" effect="dark" content="客服对该此对话的归类" placement="bottom">
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </el-checkbox>
                </li>
                <li>
                  <el-checkbox label="访客评价-fkpj">访客评价</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="手机-phone">手机</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="邮箱-emli">邮箱</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="QQ-qq">QQ</el-checkbox>
                </li>
                <li>
                  <el-checkbox label="微信-wx">微信</el-checkbox>
                </li>
              </ul>
            </el-checkbox-group>
            <el-form-item class="tianjiaziduan">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="sumbi('formziduan')">确 定</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
        <el-row class="dell">
          <el-button :disabled="xiazai">下载</el-button>
          <el-button :disabled="xiazai">删除</el-button>
        </el-row>
      </div> -->
      <!-- 表单数据 -->
      <div class="biaodan">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>

          <!-- <el-table-column v-for="(item,index) in columns" :key="index" :label="item.label" :prop="item.value" >
          </el-table-column>-->
          <el-table-column prop="id" label="ID" width="70" show-overflow-tooltip></el-table-column>
          <el-table-column prop="name" label="访客名称" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="address" label="站点名称" show-overflow-tooltip></el-table-column>
          <el-table-column label="对话时间" width="120" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.date }}</template>
          </el-table-column>
          <el-table-column prop="duihua" label="对话数(客：访)" show-overflow-tooltip></el-table-column>
          <el-table-column prop="laiyuan" label show-overflow-tooltip width="130">
            <template slot="header">
              访问来源
              <el-tooltip
                class="item"
                effect="dark"
                content="访客通过各种渠道进入网站,主要包括外部链接、搜索引擎、直接访问"
                placement="bottom"
              >
                <i class="el-icon-info biaotou1"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="ssc" label show-overflow-tooltip>
            <template slot="header">
              搜索词
              <el-tooltip class="item" effect="dark" content="搜索词或推广链接设置的关键词" placement="bottom">
                <i class="el-icon-info biaotou"></i>
              </el-tooltip>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="zxy" label show-overflow-tooltip>
            <template slot="header">
              咨询页
              <el-tooltip class="item" effect="dark" content="建立对话页面" placement="bottom">
                <i class="el-icon-info biaotou"></i>
              </el-tooltip>
            </template>
          </el-table-column> -->
          <el-table-column prop="dq" label="地区" show-overflow-tooltip></el-table-column>
          <el-table-column prop="ip" label="访客IP" show-overflow-tooltip></el-table-column>
          <el-table-column prop="phone" label="手机" show-overflow-tooltip></el-table-column>
          <el-table-column prop="emli" label="邮箱" show-overflow-tooltip></el-table-column>
          <el-table-column prop="qq" label="QQ" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
      <!-- 页码 -->
      <div class="block">
        <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          :total="sumye"
          :page-sizes="[5, 10, 20, 50, 100]"
          :page-size="Barpage"
          @size-change="dangqiang"
          @current-change="yema"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { fklist } from "../../api/jiekou";

const cityOptions = ["手机", "QQ", "邮箱", "固话"];
export default {
  name: "XianmuFkmd",

  data() {
    return {
      ruleForm: {
        value2: "", //选时间
        checkedCities: [], //对话线索
        checkedCities1: [], //消息类型
        laiyuan: "",
        suoyou: "", //搜索引擎
        type: ""
      },
      checkAll: false,
      cities: cityOptions,
      cities1: ["人工", "机器人", "留言"],

      isIndeterminate: false,
      dialogVisible: false, //显示字段设置弹出
      // 选中字段
      formziduan: {
        checkList: ["访客名称-name", "接待客服-address"]
      },
      //存渲染表头的字段
      biaotouzi: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      //消息类型
      options: [
        {
          value: "选项1",
          label: "对话类型"
        },
        {
          value: "选项2",
          label: "消息类型"
        }
      ],
      value: "对话类型",
      //搜索引擎
      options1: [
        {
          value: "选项1",
          label: "所有引擎"
        },
        {
          value: "选项2",
          label: "百度"
        },
        {
          value: "选项3",
          label: "搜狗"
        },
        {
          value: "选项4",
          label: "360"
        },
        {
          value: "选项5",
          label: "Google"
        },
        {
          value: "选项6",
          label: "神马"
        },
        {
          value: "选项7",
          label: "新浪"
        },
        {
          value: "选项8",
          label: "雅虎"
        },
        {
          value: "选项9",
          label: "Bing"
        },
        {
          value: "选项10",
          label: "今日头条"
        }
      ],
      rules: {},

      xiazai: true,
      //用户表单
      tableData: [],
      multipleSelection: [],
      currentPage4: 4,
      sumye: 0, //总页数
      Barpage: 5, //条页  选中的页码条数
      pagination: {} //存放筛选后的参数  用于页码
    };
  },

  mounted() {
    this.getlie();
    this.dangqiang();
  },

  methods: {
    // 开始筛选
    submitForm(formName) {
      let _this = this;
      this.$refs[formName].validate(async valid => {
        if (valid) {
          // console.log(_this.ruleForm);
          let time = "";
          let time1 = "";
          if (_this.ruleForm.value2) {
            time = _this.timeriqi(_this.ruleForm.value2[0]) + " " + "00:00";
            time1 = _this.timeriqi(_this.ruleForm.value2[1]) + " " + "24:00";
          }
          let duihua = [];
          if (_this.ruleForm.checkedCities) {
            _this.ruleForm.checkedCities.forEach(val => {
              if (val == "手机") {
                duihua.push("mobile");
              }
              if (val == "QQ") {
                duihua.push("qq");
              }
              if (val == "邮箱") {
                duihua.push("email");
              }
              if (val == "固话") {
                duihua.push("phone");
              }
            });
          }
          let leixin = [];
          if (_this.ruleForm.checkedCities1) {
            _this.ruleForm.checkedCities1.forEach(val => {
              if (val == "人工") {
                leixin.push("1");
              }
              if (val == "机器人") {
                leixin.push("2");
              }
              if (val == "留言") {
                leixin.push("3");
              }
            });
          }
          // // console.log(time, time1, duihua, leixin, _this.ruleForm.laiyuan)
          let res = await fklist({
            createtime: [time, time1],
            url: _this.ruleForm.laiyuan,
            type: leixin,
            clue: duihua
          });

          if (res.data.code == 200) {
            _this.tableData = [];
            _this.pagination = {
              createtime: [time, time1],
              url: _this.ruleForm.laiyuan,
              type: leixin,
              clue: duihua
            };
            this.sumye = res.data.data.count;
            res.data.data.data.forEach(val => {
              let fkdx = {};
              fkdx.id=val.id
              fkdx.name = val.visitor_name;
              fkdx.address = val.site.site_name;
              fkdx.date = _this.timestampToTime(val.createtime);
              fkdx.duihua = "";
              fkdx.laiyuan = val.url;
              fkdx.ssc = "";
              fkdx.zxy = val.url;
              fkdx.dq = val.region;
              fkdx.ip = val.ip;
              fkdx.phone = val.mobile;
              fkdx.emli = val.email;
              fkdx.qq = val.qq;
              _this.tableData.push(fkdx);
            });
            this.dangqiang();
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    //清除筛选
    resetForm(formName) {
      this.pagination = {};
      this.getlie();
      this.dangqiang();
      // console.log(this.$refs[formName]);
      this.$refs[formName].resetFields();
    },
    handleCheckAllChange(val) {
      this.ruleForm.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    handleCheckAllChange1(val) {
      this.ruleForm.checkedCities1 = val ? this.cityOptions : [];
    },
    //选择对话线索
    xuan(e) {
      if (e == "选项2") {
        this.cities1 = [
          "人工",
          "机器人",
          "智能引导",
          "场景引导",
          "留言",
          "退出挽留",
          "访客表单",
          "通话",
          "互动菜单"
        ];
      } else {
        this.cities1 = ["人工", "机器人", "留言"];
      }
    },
    //表单
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (val.length == 0) {
        this.xiazai = true;
      } else {
        this.xiazai = false;
      }
    },
    // 访客名单列表
    async getlie() {
      let _this = this;
      let res = await fklist();
      // console.log(res.data);
      if (res.data.code==-1) {
                alert("你的用户名失效，"+res.data.msg)
                this.$router.replace("/login");
            } else{
              this.sumye = res.data.data.count;
              _this.tableData = [];
              res.data.data.data.forEach(val => {
                let fkdx = {};
                fkdx.id=val.id
                fkdx.name = val.visitor_name;
                fkdx.address = val.site.site_name;
                fkdx.date = _this.timestampToTime(val.createtime);
                fkdx.duihua = "";
                fkdx.laiyuan = val.url;
                fkdx.ssc = "";
                fkdx.zxy = val.url;
                fkdx.dq = val.region;
                fkdx.ip = val.ip;
                fkdx.phone = val.mobile;
                fkdx.emli = val.email;
                fkdx.qq = val.qq;
                _this.tableData.push(fkdx);
              });
            }
    },

    // 页码  每页条数
    async dangqiang(value) {
      let _this = this;
      this.Barpage = value;
      _this.pagination.limit = value;
      let res = await fklist(_this.pagination);
      // // console.log(res.data.data.data)
      _this.tableData.length = [];
      res.data.data.data.forEach(val => {
        let fkdx = {};
        fkdx.id=val.id
        fkdx.name = val.visitor_name;
        fkdx.address = val.site.site_name;
        fkdx.date = _this.timestampToTime(val.createtime);
        fkdx.duihua = "";
        fkdx.laiyuan = val.url;
        fkdx.ssc = "";
        fkdx.zxy = val.url;
        fkdx.dq = val.region;
        fkdx.ip = val.ip;
        fkdx.phone = val.mobile;
        fkdx.emli = val.email;
        fkdx.qq = val.qq;

        _this.tableData.push(fkdx);
      });
    },
    async yema(value) {
      let _this = this;
      _this.pagination.limit = this.Barpage;
      _this.pagination.page = value;
      let res = await fklist(_this.pagination);
      // // console.log(res.data.data.data)
      _this.tableData.length = [];
      res.data.data.data.forEach(val => {
        let fkdx = {};
        fkdx.id=val.id
        fkdx.name = val.visitor_name;
        fkdx.address = val.site.site_name;
        fkdx.date = _this.timestampToTime(val.createtime);
        fkdx.duihua = "";
        fkdx.laiyuan = val.url;
        fkdx.ssc = "";
        fkdx.zxy = val.url;
        fkdx.dq = val.region;
        fkdx.ip = val.ip;
        fkdx.phone = val.mobile;
        fkdx.emli = val.email;
        fkdx.qq = val.qq;
        _this.tableData.push(fkdx);
      });
    },
    //添加字段
    sumbi(formziduan) {
      let _this = this;
      this.dialogVisible = false;
      // console.log(this.formziduan.checkList);
      this.formziduan.checkList = this.formziduan.checkList;
      this.formziduan.checkList.forEach(val => {
        let addziduan = {};
        // // console.log(val)
        addziduan.name = val.split("-")[0];
        addziduan.value = val.split("-")[1];

        _this.biaotouzi.push(addziduan);
      });
      // console.log(_this.biaotouzi);
    },

    //将时间戳转换成日期格式
    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp * 1000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    // 中国标准时间转换成日期格式
    timeriqi(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      return (time = y + "-" + m + "-" + d);
    }
  }
};
</script>

<style lang="less" scoped>
.max {
  display: flex;
}

.left {
  height: 100%;
  min-width: 280px;
}

.left .left_shaixuan {
  height: calc(100vh - 117px);
  width: 100%;
  overflow-y: scroll;
  padding: 0 10px;
}

.left .left_dianji {
  width: 100%;
  height: 55px;
  background-color: #f5f5f5;
}

.riqi {
  width: 100%;
  margin-bottom: 20px;
  padding: 3px 0px;
}

.riqi ::v-deep input {
  font-size: 12px !important;
}

.duihua {
  margin-right: 4px !important;
  margin-bottom: 5px;
}

.leixin {
  width: 35%;
}

.leixin ::v-deep .el-input__inner {
  border: none;
  padding: 0;
}

.fangwen ::v-deep .el-form-item {
  margin-bottom: 0px !important;
}

.fangwen ::v-deep .el-form-item .el-form-item__label {
  padding: 0 !important;
  position: relative;
}

.yinqin {
  width: 100%;
}

.left_dianji ::v-deep .el-form-item__content {
  float: right;
  padding-top: 11px;
  margin-left: 0px !important;
}

.laiyuan {
  position: absolute;
  left: 60px;
  top: -25px;
}

.ssc {
  position: absolute;
  left: 54px;
  top: -25px;
}

.chongzhi {
  background: transparent;
  border: none;
}

.right {
  height: calc(100vh - 70px);
  overflow-y: scroll;
  width: 100%;
  padding: 10px 10px 0 22px;
}

.right .right_top {
  width: 100%;
  display: flex;
}

.right .right_top .xianshi {
  padding: 5px;
  border: 1px solid #d5d8db;
  color: #28334b;
  font-size: 12px;
  border-radius: 0px;
  margin-right: 10px;
}

.right .right_top .xianshi:hover {
  color: #1890ff;
  border: 1px solid #1890ff;
}

.ziduan ::v-deep .el-dialog__header {
  background: #f7f8fa;
  box-shadow: inset 0 -1px 0 0 #ced5e0;
}

::v-deep .el-dialog__body .el-checkbox-group {
  display: flex;
}

::v-deep .el-dialog__body ul:nth-child(1) {
  padding: 0px;
}

::v-deep .el-dialog__body ul li {
  list-style: none;
  margin-bottom: 10px;
}

.dell ::v-deep .el-button {
  padding: 5px 10px;
  border-radius: 0px;
}

::v-deep .el-date-editor .el-input__icon:nth-child(1) {
  display: none;
}

.biaodan {
  margin: 10px 0;
}

.biaodan
  ::v-deep
  .el-table
  .el-table__header-wrapper
  .el-table__header
  .has-gutter
  tr
  th {
  background: #f4f7fb;
  color: #8da2b5;
}

.biaotou1 {
  position: absolute;
  left: 66px;
  top: 4px;
}

.biaotou {
  position: absolute;
  left: 52px;
  top: 4px;
}

.block {
  display: flex;
  justify-content: end;
}

.block ::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin-top: 8px;
}

.block ::v-deep .el-pagination__sizes {
  margin-right: 0;
}

.block ::v-deep .el-pagination__sizes .el-input {
  margin-right: 0;
}

.block ::v-deep .el-pager li,
.block ::v-deep .el-pagination button {
  border: 1px solid #ebebeb;
  background-color: #fff;
  font-weight: initial;
  margin: 0 3px;
}

.time {
  margin-bottom: 0px !important;
}

.time ::v-deep .el-form-item__content {
  margin-left: 0px !important;
}

.mgbo {
  margin-bottom: 5px !important;
}

.mgbo ::v-deep .el-form-item__label {
  padding: 0 !important;
}

.tianjiaziduan {
  display: flex;
  justify-content: end;
}
</style>