import { render, staticRenderFns } from "./fkmd.vue?vue&type=template&id=408a50e8&scoped=true&"
import script from "./fkmd.vue?vue&type=script&lang=js&"
export * from "./fkmd.vue?vue&type=script&lang=js&"
import style0 from "./fkmd.vue?vue&type=style&index=0&id=408a50e8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "408a50e8",
  null
  
)

export default component.exports